<template>
    <div class="p-pegas">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">
                                {{ $t("message.vacation_planes") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :className="'w100'"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            @c-create="drawerCreate = true"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            :columns="columns"
                            @c-change="updateColumn"
                            :v_can_create="'VacationManagementController@store'"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->
            <div class="card-table-header table-crm-smart">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>
                            <th v-if="columns.year.show">
                              {{ columns.year.title }}
                            </th>
                            <th v-if="columns.branch.show">
                              {{ columns.branch.title }}
                            </th>
                            <th v-if="columns.staff.show">
                              {{ columns.staff.title }}
                            </th>
                            <th v-if="columns.staff.show">
                            </th>
                            <th v-if="columns.created_at.show">
                              {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>
                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                              <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.id"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>
                            <th v-if="columns.year.show">
                              <el-date-picker
                                v-model="filterForm.year"
                                  type="year"
                                  :size="'mini'"
                                  :format="'yyyy'"
                                  :value-format="'yyyy'"
                                  style="width:100%">
                              </el-date-picker>
                            </th>
                            <th v-if="columns.branch.show">
                              <select-branch
                                  :placeholder="columns.branch.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.branch_id"
                                >
                              </select-branch>
                            </th>
                            <th v-if="columns.staff.show">
                              <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.staff_count"
                                :class="mode ? 'filter__day' : 'filter__night'"
                            ></el-input>
                            </th>
                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                  :placeholder="columns.created_at.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.created_at"
                                ></crm-date-picker>
                            </th>
                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>
                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>
                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="vacation_plane in list"
                            :key="vacation_plane.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">{{ vacation_plane.id }}</td>
                            <td v-if="columns.year.show">{{ vacation_plane.year }}</td>
                            <td v-if="columns.branch.show">{{ vacation_plane.branch?(vacation_plane.branch.name):'' }}</td>
                            <td v-if="columns.staff.show">
                              {{ vacation_plane.staff_count }}
                            </td>
                            <td v-if="columns.staff.show">
                              <router-link  :to="{ name: 'vacationPlaneStaff', params: { id: vacation_plane.id }}" >
                               <el-button type="primary" size="small">{{$t('message.staff')}}</el-button>
                              </router-link>
                            </td>
                            <td v-if="columns.created_at.show">
                                {{ vacation_plane.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ vacation_plane.updated_at }}
                            </td>

                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="vacation_plane"
                                    :permissionShow="'VacationManagementController@update'"
                                    :permissionDestroy="'VacationManagementController@destroy'"
                                    :actions="actions"
                                    @delete="destroy"
                                ></crm-settings>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                </div>
            </div>

            <div class="app-modal app-modal__full">
                <el-drawer
                    :with-header="false"
                    :wrapperClosable="false"
                    :visible.sync="drawerCreate"
                    size="70%"
                    ref="drawerCreate"
                    @opened="drawerOpened('drawerCreateChild')"
                    @closed="drawerClosed('drawerCreateChild')"
                  >
                    <crm-create
                        ref="drawerCreateChild"
                        drawer="drawerCreate"
                    ></crm-create>
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :wrapperClosable="false"
                    :visible.sync="drawerUpdate"
                    size="70%"
                    ref="drawerUpdate"
                    @opened="drawerOpened('drawerUpdateChild')"
                    @closed="drawerClosed('drawerUpdateChild')"
                >
                    <crm-update
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    ></crm-update>
                </el-drawer>
            </div>
        </div>
    </div>
</template>
<script>
import list from "@/utils/mixins/list";
import moment from 'moment';
import selectStaff from "@/components/filters/inventory/select-staff";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
    name: "RegionController",
    mixins: [list],
    components: {
        CrmCreate,
        CrmUpdate,
        selectStaff
    },

    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            list: "vacationPlane/list",
            columns: "vacationPlane/columns",
            pagination: "vacationPlane/pagination",
            statues: "vacationPlane/statues",
            filter: "vacationPlane/filter",
            sort: "vacationPlane/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "vacationPlane/index",
            setPagination: "vacationPlane/setPagination",
            updateSort: "vacationPlane/updateSort",
            updateFilter: "vacationPlane/updateFilter",
            updateColumn: "vacationPlane/updateColumn",
            updatePagination: "vacationPlane/updatePagination",
            show: "vacationPlane/show",
            empty: "vacationPlane/empty",
            delete: "vacationPlane/destroy",
            refreshData: "vacationPlane/refreshData",
        }),
        formatDate(value){
          if (value) {
            return moment((value)).lang("ru").format('MMMM YYYY')
          }
        },
        formatPeryotDate(value){
          if (value) {
            return moment((value)).lang("ru").format('DD.MM.YYYY')
          }
        }
    },
};
</script>

